import React, { useCallback, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import { Provider } from 'react-redux';
import store from './store';

const App = () => {
  const location = useLocation();
  const onRouteChanged = useCallback(() => {
    const fullPageLayoutRoutes = [
      '/',
      '/home',
      '/plans',
      '/login',
      '/register',
      '/recover',
      '/reset',
      '/verify',
      '/404',
      '/access',
      '/terms-of-use',
      '/privacy-policy',
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        document
          .querySelector('.page-body-wrapper')
          .classList.add('full-page-wrapper');
        break;
      } else {
        document
          .querySelector('.page-body-wrapper')
          .classList.remove('full-page-wrapper');
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    onRouteChanged();
  }, [onRouteChanged]);

  let navbarComponent =
    location.pathname === '/' ||
      location.pathname === '/home' ||
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/plans' ||
      location.pathname === '/recover' ||
      location.pathname === '/reset' ||
      location.pathname === '/verify' ||
      location.pathname === '/access' ||
      location.pathname === '/entry' ||
      location.pathname === '/terms-of-use' ||
      location.pathname === '/privacy-policy' ||
      location.pathname === '/404' ? null : (
      <Navbar />
    );
  return (
    <Provider store={store}>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          {navbarComponent}
          <div className="main-panel">
            <AppRoutes />
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default withRouter(App);
